<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-02-02 10:00:38
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-02-02 11:47:30
 * @FilePath: /yiyun_project/src/views/shortVideo/basicSet.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-main>
    <el-tabs type="card" v-model="activeType" @tab-click="tabChange" :before-leave="beforeLeave">
      <el-tab-pane v-for="item in typeList" :key="item.type" :label="item.name" :name="item.type"></el-tab-pane>
    </el-tabs>
    <el-form ref="form" :model="dataInfo" label-width="120px">
      <el-form-item label="协议名称">
        <el-input style="width: 300px" v-model="dataInfo.title" disabled></el-input>
      </el-form-item>
      <el-form-item label="协议内容">
        <RichText v-if="showTxt" :richTxt="dataInfo.rules" width="600" height="400" @soninfo="changeRule"></RichText>
      </el-form-item>
    </el-form>
    <Preservation @preservation="Preservation()"></Preservation>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
import RichText from '@/components/richText';
export default {
  components: {
    RichText,
    Preservation,
  },
  // 协议类型1用户协议 2—隐私协议 3—法律声明 4—关于我们 5—vip会员说明  6—充值注意事项 7—联系我们 8—分销协议
  data() {
    return {
      activeType: 1,
      typeList: [
        { type: 1, name: '用户协议' },
        { type: 2, name: '隐私协议' },
        { type: 3, name: '法律声明' },
        { type: 4, name: '关于我们' },
        { type: 5, name: 'vip会员说明' },
        { type: 6, name: '充值注意事项' },
        { type: 7, name: '联系我们' },
        { type: 8, name: '分销协议' },
      ],
      oldInfo: {
        title: '',
        rules: '',
      },
      dataInfo: {
        title: '',
        rules: '',
      },
      showTxt: true,
      next: false,
    };
  },
  watch: {
    activeType() {
      this.showTxt = false;
      this.getBasicInfo();
    },
  },
  created() {
    this.getBasicInfo();
  },
  methods: {
    changeRule(val) {
      this.dataInfo.rules = val;
    },
    saveInfo() {
      this.oldInfo.title = this.dataInfo.title;
      this.oldInfo.rules = `<p>${this.dataInfo.rules}</p>`;
    },
    beforeLeave(activeName, oldActiveName) {
      if (this.next) {
        this.next = false;
        return true;
      }
      this.$confirm('请确认是否已保存该协议?', '提示', {
        confirmButtonText: '保存并跳转',
        cancelButtonText: '跳转',
        type: 'warning',
      })
        .then(() => {
          this.Preservation(activeName);
        })
        .catch(() => {
          this.next = true;
          this.activeType = activeName;
        });
      return false;
    },
    getBasicInfo() {
      this.$axios
        .post(this.$api.shortVideo.basic_set, {
          type: this.activeType,
        })
        .then(res => {
          if (res.code == 0) {
            this.dataInfo.title = res.result.title;
            this.dataInfo.rules = res.result.rules;
            this.showTxt = true;
            this.saveInfo();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    Preservation(activeName) {
      this.$axios
        .post(this.$api.shortVideo.set_basic, {
          type: this.activeType,
          title: this.dataInfo.title,
          rules: this.dataInfo.rules,
        })
        .then(res => {
          if (res.code == 0) {
            let name = this.typeList.find(i => i.type == this.activeType).name;
            if (activeName) {
              this.next = true;
              this.activeType = activeName;
            }
            this.$message.success(name + '保存成功');
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  min-height: 100%;
  padding-bottom: 100px;
}
</style>
